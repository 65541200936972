import { Component } from 'react'
import '../css/Login.css'
// import LOGO from '../../public/images/logofooter.png'

class Login extends Component { 
  state = { 
    isPassword: true
  }

  render () {
    const { isPassword } = this.state

    return <>
      <div className="login-container">
        <div className="login-container-child" align="center">
          <div className="card" data-aos="zoom-out">
            <div className="card-body">
              <form>
                <div className="row g-4">

                  <div className="col-12">
                    <img className="form-logo" class="img-fluid" src={process.env.PUBLIC_URL + '/assets/images/UGP_Logo.png'} alt=""/>
                  </div>
                 
                  <div class="col-12">
                    <input type="text" class="form-control form-control-lg" id="username" name="username" placeholder="Username/Email"/>
                  </div>
                  <div class="col-12">
                    <input type="password" class="form-control form-control-lg" id="password" name="password" placeholder="Password"/>
                  </div>

                  {/* <div className="col-12">
                    <input type="text" id="username" name="username" placeholder="Username/Email" required/>
                  </div>

                  <div className="col-12 pass-input-container">
                    <input type={isPassword ? 'password' : 'text'} id="password" name="password" placeholder="Password" required/>

                    <div className="pass-icon" onClick={() => this.passwordHide()}>
                      <i className={isPassword ? 'fas fa-eye' : 'fas fa-eye-slash'} style={{ fontSize: 25 }}/>
                    </div>
                  </div> */}

                  <div className="col-12">
                    <button type="submit" class="btn btn-danger login-btn btn-lg">
                      LOGIN
                    </button>
                  </div>

                  <div align="right" className="col-12">
                    <span className="forgot-pass-lbl">
                      Forgot password?
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  passwordHide = () => {
    const { isPassword } = this.state

    this.setState({ isPassword: !isPassword })
  }
}

export default Login