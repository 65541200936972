import Header from './components/Header'
import Footer from './components/Footer'

import Container from './components/Container'
import ComingSoon from './components/ComingSoon'
import Login from './components/Login'

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import Aos from 'aos'
import "aos/dist/aos.css"
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'


function App() {
  Aos.init({
    duration: 2000
  })

  return (
    <div className="App" 
        // style={{ 
        //     backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/Generics.png'})`,
        //     backgroundSize: 'cover',
        //     backgroundPosition: 'center'
        //   }}
      >
      <Header/>

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Container/>}/>

          <Route path='/login' element={<Login/>}/>

          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </BrowserRouter>
      
      {
        window.location.pathname === '/' &&
        <Footer/>
      }
    </div>
  );
}

export default App;
