import React, { Component } from 'react'
import '../css/Header.css'

class Header extends Component { 
  render () {
    return <>
      <div>

        {/* <div className="header sticky-top" data-aos="fade-down">
          <img className="header-logo" src={process.env.PUBLIC_URL + '/assets/images/UGP_Logo.png'} alt="" onClick={() => this.goTo('Home')}/>

          <span className="header-label" onClick={() => window.location.replace('/')}>
            Home
          </span>

          {
            window.location.pathname === '/' &&
            <React.Fragment>
              <span className="header-label" onClick={() => this.scrollTo("aboutus")}>
                About Us
              </span>

              <span className="header-label" onClick={() => this.scrollTo("offers")}>
                Offers
              </span> 

              <span className="header-label" onClick={() => this.scrollTo("contact")}>
                Contact
              </span> 

              <button type="button" className="btn btn-info btn-header" onClick={() => this.goTo('Login')} style={{ float: 'right' }}>
                LOGIN
              </button>
            </React.Fragment>
          }
        </div> */}

        <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
          <div class="container-fluid">
            <a class="navbar-brand" href="#" onClick={() => window.location.replace('/')}> Home </a>
           
              {
              window.location.pathname === '/' &&
                <React.Fragment>
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
           
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <li class="nav-item">
                        <span class="nav-link" onClick={() => this.scrollTo("aboutus")}>About Us</span>
                      </li>
                      <li class="nav-item">
                        <span class="nav-link" onClick={() => this.scrollTo("offers")}>Offers</span>
                      </li>
                      <li class="nav-item">
                        <span class="nav-link" onClick={() => this.scrollTo("contact")}>Contact</span>
                      </li>
                    </ul>
                    <button class="btn btn-navbar" type="button" onClick={() => this.goTo('Login')}>Login</button>
                  </div>
                </React.Fragment>
              }
        
          </div>
        </nav>
       

      </div>
    </>
  }

  goTo = (link) => {
    if (link === "Home") {
      window.location.replace('/')
    } else if (link === "Login") {
      window.location.replace('/Login')
    }
  }

  scrollTo = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" })
  }

}

export default Header