import { Component } from 'react';
import '../css/Footer.css'

class Footer extends Component { 
  render () {
    return <>
      <div>
        <section class="deneb_cta" id="contact">
          <div class="container">
            <div class="cta_wrapper">
              <div class="row align-items-center">
                <div class="col-lg-7">
                  <div class="cta_content">
                    <h3>Now Open for Franchise!</h3>
                    <p>Inquire on us for more information.</p>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="button_box">
                    <a href="#" class="btn btn-danger btn-header">Inquire Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="deneb_footer">
          <div class="widget_wrapper">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="widget widegt_about">
                    <div class="widget_title">
                    </div>
                    <p>Some of the best-managed sister companies of UGP are Unified Products and Services Inc. (Unified),
                      Global Pinoy Travel and Tours (GPTT), PHILLANDS Realty, and Accretio Holdings Inc. These companies
                      work together to provide a sustainable platform for all aspiring entrepreneurs, especially OFWs who
                      want a fast-growing business solution.
                    </p>
                    <ul class="social">
                      <li><a href="https://www.facebook.com/unifiedgenericsofficial"><i class="fab fa-facebook-f"></i></a></li>
                      <li><a href="https://ugp.ph/"><i class="fas fa-globe"></i></a></li>
                      <li><a href="https://unified.ph/"><i class="fas fa-link"></i></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <div class="widget widget_link">
                    <div class="widget_title">
                      <h4>Other Links</h4>
                    </div>
                    
                    <div className="mb-4">
                      <span className="content-label link" onClick={() => window.open('https://unified.ph/')}>
                        <i className="fas fa-link"/> &nbsp; Unified Products and Services
                      </span>
                    </div>

                    <div className="mb-4">
                      <span className="content-label link" onClick={() => window.open('https://www.facebook.com/AccretioHoldingsIncOfficial')}>
                      <i className="fab fa-facebook-square"/> &nbsp; Accretio Holdings Inc.
                      </span>
                    </div>

                    <div className="mb-4">
                      <span className="content-label link" onClick={() => window.open('https://ecashpay.com/')}>
                        <i className="fas fa-link"/> &nbsp; Ecashpay
                      </span>
                    </div>

                    <div className="mb-4">
                      <span className="content-label link" onClick={() => window.open('https://gocab.ph/')}>
                        <i className="fas fa-link"/> &nbsp; GoCab
                      </span>
                    </div>

                    <div className="mb-4">
                      <span className="content-label link" onClick={() => window.open('https://www.facebook.com/globalpinoytravel')}>
                        <i className="fab fa-facebook-square"/> &nbsp; Global Pinoy Travel and Tours
                      </span>
                    </div>
                    
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <div class="widget widget_contact">
                    <div class="widget_title">
                      <h4>Contact Us</h4>
                    </div>
                    <div class="contact_info">
                      <div class="single_info">
                        <div class="icon">
                          <i class="fas fa-phone-alt"></i>
                        </div>
                        <div class="info">
                          <p>(02) 373-1215</p>
                          <p>0929-777-6855</p>
                          <p>0917-329-0832</p>
                        </div>
                      </div>
                      <div class="single_info">
                        <div class="icon">
                          <i class="fas fa-envelope"></i>
                        </div>
                        <div class="info">
                          <p>franchise.main@ugp.ph</p>
                        </div>
                      </div>
                      <div class="single_info">
                        <div class="icon">
                          <i class="fas fa-globe"></i>
                        </div>
                        <div class="info">
                          <p>www.ugh.ph</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright_area">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="copyright_text">
                    <p>Copyright &copy; 2022 All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  }
}

export default Footer