import React, { Component } from 'react'
import '../css/Container.css'

class Container extends Component { 
  render () {
    return <>
      <div>
  
        <div className="page-header">
          <div className="layer">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="page-caption">
                    <h1 className="page-title">UNIFIED GENERICS</h1>
                    <h2 className="page-title">UGP - Uunlad at Gagaling ang Pilipino</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-section">
          <div className="container">
              <div className="card-block mb30" data-aos="fade-down">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section-title mb-0">
                      <h4>With its motto, Uunlad at Gagaling ang Pilipino,</h4>
                      <p>UGP is committed to providing everyone with quality products and services and ensure good health, happy customers, and secure futures.</p>
                      <p>Unified Generics Pharmacy and Minimart (UGP) is an emerging generics pharmacy that aspires to be a
                        household brand as a one-stop-shop store, where people can get high-quality and affordable medicines,
                        retail products, and tech-based services</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div align="left" style={{ paddingBottom: 150 }} id="aboutus">
          <div className="row gx-5 content-2">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="pb-3" align="center">
                <span className="title">
                  About Us
                </span>
              </div>

              <div className="d-flex justify-content-center row gx-5 gy-5">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="card front-page-card">
                    <div className="card-body front-page-card-body">
                      <div className="pb-3">
                        <i className="fas fa-bullseye" style={{ fontSize: 70, color: '#598ced' }} />
                      </div>
                      <div className="pb-3">
                        <span className="title">
                          OUR VISION
                        </span>
                      </div>
                      <span className="content-label">
                        To be one of the preferred customer centric and innovative pharmacies in the Philippines by 2026
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="card front-page-card">
                    <div className="card-body front-page-card-body">
                      <div className="pb-3">
                        <i className="fas fa-check-circle" style={{ fontSize: 70, color: '#598ced' }} />
                      </div>
                      <div className="pb-3">
                        <span className="title">
                          OUR MISSION
                        </span>
                      </div>
                      <span className="content-label">
                        We commit to provide affordable, high quality products for both local and global pinoys To create entrepreneurial opportunities
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="card front-page-card">
                    <div className="card-body front-page-card-body">
                      <div className="pb-3">
                        <i className="fas fa-child" style={{ fontSize: 70, color: '#598ced' }} />
                      </div>

                      <div className="pb-3">
                        <span className="title">
                          OUR CORE VALUES
                        </span>
                      </div>
                      <span className="content-label">
                        Compassion, Customer oriented, Innovative, Integrity, Reliability
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container" align="center" style={{ paddingBottom: 150 }} id="offers">
          <div className="row gx-5 content-3">
            <div className="pb-3" align="center">
              <span className="title">
                UGP also Offers Tech-Based Services:
              </span>
            </div>
            <div className="mt-5">
              <div className="d-style btn btn-brc-tp border-2 bgc-white w-100 my-2 shadow-sm list-card">                     
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <h4 className="pt-3 text-170 text-600 text-primary-d1 letter-spacing">
                    <i className="fas fa-money-bill-wave" style={{ fontSize: 40, color: '#598ced' }}/>  Bills Payment
                  </h4>
                </div>
                <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-4 my-md-0">
                  <li>
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span>
                      <span className="text-110 content-label">Water Utilities</span>
                    </span>
                  </li>
                  <li className="mt-25">
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span className="text-110 content-label">
                      Electric Companies
                    </span>
                  </li>
                  <li className="mt-25">
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span className="text-110 content-label">
                      Pre-Need Insurance
                    </span>
                  </li>
                  <li className="mt-25">
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span className="text-110 content-label">
                    Credit Cards
                    </span>
                  </li>
                  <li className="mt-25">
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span className="text-110 content-label">
                    Telecommunication
                    </span>
                  </li>
                </ul>
                <div className="col-12 col-md-4 text-center">
                  <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-4 my-md-0">
                    <li>
                      <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                      <span>
                        <span className="text-110 content-label">Cable Companies</span>
                      </span>
                    </li>
                    <li className="mt-25">
                      <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                      <span className="text-110 content-label">
                        School Tuition Fees
                      </span>
                    </li>
                    <li className="mt-25">
                      <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                      <span className="text-110 content-label">
                      Charitable Institutions
                      </span>
                    </li>
                    <li className="mt-25">
                      <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                      <span className="text-110 content-label">
                      Banks / Financial Institutions
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="d-style btn btn-brc-tp border-2 bgc-white w-100 my-2 shadow-sm list-card">
              <div className="row align-items-center">
                <div className="col-12 col-md-4">
                  <h4 className="pt-3 text-170 text-600 text-primary-d1 letter-spacing">
                    <i className="far fa-credit-card" style={{ fontSize: 40, color: '#598ced' }}/> E-loading 
                  </h4>
                </div>
                <ul className="list-unstyled mb-0 col-12 col-md-4 text-dark-l1 text-90 text-left my-4 my-md-0">
                  <li>
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span>
                      <span className="text-110 content-label">Domestic (Smart, Globe, Sun)</span>
                    </span>
                  </li>
                  <li className="mt-25">
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span className="text-110 content-label">
                      International (United Arab Emirates, Qatar, Singapore, Hong Kong)
                    </span>
                  </li>
                  <li className="mt-25">
                    <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1"></i>
                    <span className="text-110 content-label">
                    e-Insurance (Maxicare Insurance, FPG Insurance, Malayan Insurance)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  }
}

export default Container