import { Component } from 'react'
import '../css/ComingSoon.css'

class ComingSoon extends Component { 
  render () {
    return <>
      <div className="comming-soon-container" data-aos="zoom-in">
        <div className="comming-soon-container-child" align="center">
          <div className="banner-container">

            <div className="mt-3">
              <span className="coming-soon-subtitle">
                coming soon, stay tuned!
              </span>
            </div>

            <div>
              <div className="coming-soon-title typing-demo">
                our website is almost ready.
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  }
}

export default ComingSoon